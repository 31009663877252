import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { PracticePathBaseInformations } from 'src/app/classes/model/practice-path';
import { PracticePathService } from 'src/app/services/practice-path.service';
import { NarrationPlayerDialogOpenerService } from './services/narration-player-dialog-opener.service'; 

export type NarrationsDuringRecordingDialogInput = {
  practicePathUuid:string,
  initialTab?:"list"|"upload";
}

@Component({
  selector: 'app-narrations-during-recording-dialog',
  templateUrl: './narrations-during-recording-dialog.component.html',
  styleUrls: ['./narrations-during-recording-dialog.component.scss'],
  providers: [
    NarrationPlayerDialogOpenerService
  ]
})
export class NarrationsDuringRecordingDialogComponent implements OnInit {

  selectedTabIndex:number;

  practicePath:PracticePathBaseInformations;

  isPracticePathLoading:boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:NarrationsDuringRecordingDialogInput,
    private practicePathService:PracticePathService,
    private notifierService:NotifierService,
    private dialogRef:MatDialogRef<NarrationsDuringRecordingDialogComponent, PracticePathBaseInformations>,
    private narrationPlayerDialogOpenerService:NarrationPlayerDialogOpenerService
  ) { }

  ngOnInit(): void {
    this.selectedTabIndex = this.data.initialTab === "upload" ? 1 : 0;

    // Initalize the practice path
    this.initilaizePracticePath();

    // Subscribe to the narration player dialog opening
    this.subscribeToNarrationPlayerDialogOpening();
  }

  /**
   * Initalizes the (fresh) practice path for the dialog.
   */
  private async initilaizePracticePath():Promise<void> {
    // Set the practice path loading to true
    this.isPracticePathLoading = true;

    try {
      // Fetch the practice path (in summary mode)
      this.practicePath = await this.practicePathService.fetchPracticePath(
        this.data.practicePathUuid,
        "summary"
      );
    } catch(error:any) {
      // If an error happens, display an error notification an close the dialog
      console.error(error);
      this.notifierService.notify("error", "Hiba az útvonal megnyitásakor.");
      this.dialogRef.close();
    }

    // Set the practice path loading to false
    this.isPracticePathLoading = false;
  }

  /**
   * Subscribes to the narration player dialog's opening. On the opening on that dialog, this (narrations 
   * during recording) dialog will be closed.
   */
  private subscribeToNarrationPlayerDialogOpening():void {
    this.narrationPlayerDialogOpenerService.narrationPlayerDialogOpened$.subscribe(
      () => {
        this.dialogRef.close(this.practicePath);
      }
    )
  }

  /**
   * Opens a 'narrations during recording' dialog with the provided datas.
   * 
   * @param dialogService the dialog service to open the dialog with
   * @param data the data to open the dialog with
   * 
   * @returns reference to the opened dialog
   */
  public static open(
    dialogService:MatDialog,
    data:NarrationsDuringRecordingDialogInput,

  ):MatDialogRef<NarrationsDuringRecordingDialogComponent, PracticePathBaseInformations> {
    return dialogService.open<
      NarrationsDuringRecordingDialogComponent,
      NarrationsDuringRecordingDialogInput,
      PracticePathBaseInformations
    >(
      NarrationsDuringRecordingDialogComponent,
      {
        data: data,
        height: "80vh",
        width: "800px",
        disableClose: true
      }
    );
  }

  /**
   * Handles the event when a new narration is uploaded in the upload tab component. When that occurs
   * the dialog automatically switches to the narrations list tab.
   */
  protected onNarrationsUploaded():void {
    this.selectedTabIndex = 0;
  }

  /**
   * Handles the tab index change event of the contained mat-tab element. 
   * 
   * @param newTabIndex the new tab index
   */
  protected tabIndexChange(newTabIndex:number):void {
    this.selectedTabIndex = newTabIndex;
  }

  /**
   * Handles the close button click. On the click event the dialog is closed and it return
   * the actual practice path.
   */
  protected onCloseButtonClick():void {
    this.dialogRef.close(this.practicePath);
  }
}
