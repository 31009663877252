import { GoogleMapPosition } from './../../classes/model/google-map-position';
import { SnapToRoadService } from './../../services/snap-to-road-service';
import { ConfirmationDialogService } from "src/app/modules/confirmation-dialog/services/confirmation-dialog.service";
import { AudioPlayerService } from "./services/audio-player-service";
import { PracticePathEditorSplitViewService } from "./services/split-view.service";
import { first } from "rxjs/operators";
import { PathIconMapController } from "./path-icon-map-controller";
import { TabScrollPositionRecoveryService } from "./../../services/tab-position-recovery.service";
import { MsToTimeStringPipe } from "./pipes/ms-to-time-pipe";
import { MenuWindowOverlay } from "./components/google-map-extended/overlays/menu-window-overlay";
import { PracticePathGlobalVideoEditorPageService } from "./services/practice-path-global-video-editor-page.service";
import {
  CriticalPointAssignment,
  PathItem,
  StopPoint,
} from "./../../classes/model/practice-path";
import { RotateMarkerIconUtil } from "./components/google-map-extended/RotatedMarkerIconUtil";
import { PracticePathUtil } from "../../services/practice-path-util";
import { NotifierService } from "angular-notifier";
import { CriticalPoint } from "src/app/classes/model/practice-path";
import { PracticePathService } from "src/app/services/practice-path.service";
import { SingleMarkerController } from "./components/google-map-extended/single-marker-controller";
import { MarkerUtil } from "./components/google-map-extended/marker-util";
import { PipedObservableArray } from "./models/ObservableArray";
import { BehaviorSubject, skipWhile } from "rxjs";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { GoogleMapExtendedController } from "./components/google-map-extended/google-map-controller";
import {
  MarkerClickEvent,
  MarkeredPolylineController,
} from "./components/google-map-extended/markered-polyline-controller";
import { UnderlyingMarker } from "./components/google-map-extended/marker-types";
import { ActivatedRoute } from "@angular/router";
import { LatLng } from "./models/LatLng";
import deepEqual from "fast-deep-equal";
import { MatTabChangeEvent, MAT_TABS_CONFIG } from "@angular/material/tabs";

export enum InitState {
  LOADING,
  ERROR,
  NO_ACCESS,
  NO_VIDEO,
  NOT_EXIST_PATH,
  SUCCESS
}

@Component({
  selector: "app-practice-path-video-editor-page",
  templateUrl: "./practice-path-video-editor-page.component.html",
  styleUrls: ["./practice-path-video-editor-page.component.scss"],
  providers: [
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: 190 } },
    PracticePathGlobalVideoEditorPageService,
    TabScrollPositionRecoveryService,
    PracticePathEditorSplitViewService,
  ],
})
export class PracticePathVideoEditorPageComponent
  implements OnInit, AfterViewInit, OnDestroy {
  InitState: typeof InitState = InitState;
  displayedLeftTableIconsUrl: string[] = [];
  displayedTopIndexIconsUrl: string[] = [];

  isKeyboardShortcutEnabled: boolean = true;

  googleMapController!: GoogleMapExtendedController;
  isMapInitDone: boolean = false;

  // A sárga útvonalat kezelő controller
  markeredPolylineControllerForPath!: MarkeredPolylineController;

  // A hozzárendelt kritikus pontokat kezeli
  markeredPolylineForAssignedCriticalPoint!: MarkeredPolylineController;
  mappedAssignedCriticalPoints$: PipedObservableArray<
    CriticalPointAssignment,
    UnderlyingMarker
  >;

  // A NEM hozzárendelt kritikus pontok
  markeredPolylineForNotAssignedCriticalPoints!: MarkeredPolylineController;
  mappedNotAssignedCriticalPoint$: PipedObservableArray<
    CriticalPoint,
    UnderlyingMarker
  >;

  // Az autó pozíciójához tartozó marker controllere
  carMarkerController: SingleMarkerController;

  // Az autó pozíciója. Ha változik, akkor újra renderelődik a térképen is a marker
  // (A carMarkerController fel van iratkozva erre a Subject-re)
  carPosition$ = new BehaviorSubject(
    new UnderlyingMarker({ latitude: 0, longitude: 0 })
  );

  // Utolsó zoom level teljes 'W" betűvel való zoomolás ELŐTT
  // ennek a segítségével állítjuk vissza a zoom-ot
  lastZoomLevelBeforeWZoom: number | undefined;

  activatedStopPoint: {
    criticalPoint: CriticalPoint | null;
    stopPoint: StopPoint;
    index: number;
  } = null;
  lastActivatedStopPoint: {
    criticalPoint: CriticalPoint | null;
    stopPoint: StopPoint;
  } = null;

  defaultErrorMessage = "Az útvonal betöltése nem sikerült. Próbáld újra, vagy írj az info@mrkresz.hu címre";
  errorMessageOnInit: string = this.defaultErrorMessage;
  initState: InitState;

  constructor(
    public practicePathService: PracticePathService,
    private activatedRoute: ActivatedRoute,
    public notifier: NotifierService,
    private practicePathUtil: PracticePathUtil,
    public globalPracticePathEditorService: PracticePathGlobalVideoEditorPageService,
    public tabScrollPositionRecoveryService: TabScrollPositionRecoveryService,
    private zone: NgZone,
    protected practiceSplitViewService: PracticePathEditorSplitViewService,
    private changeDetector: ChangeDetectorRef,
    private audioPlayer: AudioPlayerService,
    private confirmationDialogService: ConfirmationDialogService,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private snapToRoadService: SnapToRoadService
  ) { }
  ngOnDestroy(): void {
    this.googleMapController?.dispose();
    this.globalPracticePathEditorService?.dispose();
    this.markeredPolylineControllerForPath?.dispose();
    this.markeredPolylineForAssignedCriticalPoint?.dispose();
    this.mappedAssignedCriticalPoints$?.destroy();
    this.markeredPolylineForNotAssignedCriticalPoints?.dispose();
    this.mappedNotAssignedCriticalPoint$?.destroy();
    this.mappedNotAssignedCriticalPoint$ = null;
    this.carMarkerController?.dispose();
    this.carPosition$?.unsubscribe();
    this.globalPracticePathEditorService.pathIconMapController?.destroy();
    this.tabScrollPositionRecoveryService?.dispose();
  }

  ngAfterViewInit(): void { }
  ngOnInit(): void {
    this.initEditor();
  }


  async initEditor() {
    this.initState = InitState.LOADING;

    // Először nézzük meg, hogy van-e hozzáférése a path-hez
    try {
      const hasAccess = await this.globalPracticePathEditorService.hasAccessToPath(this.getPracticePathUuidFromUrl());
      if (!hasAccess) {
        this.initState = InitState.NO_ACCESS;
        return;
      }
    } catch (e) {
      if (e.error.error == 'NOT_FOUND_ENTITY') {
        this.initState = InitState.NOT_EXIST_PATH;
      } else {
        this.initState = InitState.ERROR;
      }
      return;
    }


    // Hamissal tér vissza, ha nem tudta inicializálni
    // az init hibaüzenetet jelenít meg, ha nem sikerült
    const result = await this.globalPracticePathEditorService.init(
      this.activatedRoute.snapshot.params.practicePathUuid
    );
    if (!result) {
      this.initState = InitState.ERROR;
      this.errorMessageOnInit = "Az útvonal betöltése nem sikerült! Létezik az útvonal? Keresd meg az útvonalakban.";
      return;
    }

    if (
      this.globalPracticePathEditorService.determineVideoUrl(this.globalPracticePathEditorService.practicePath).type == "NONE"
    ) {
      this.initState = InitState.NO_VIDEO;
      return;
    }

    // Ezen a ponton biztos, hogy létezik a path, a service betöltötte, és van megjeleníthető videó is
    this.createPipedObservableArrays();
    this._addVideoPlayerListeners();
    this.addListenerToIconChanges();
    this.addGeneralEventListeners();

    this.initState = InitState.SUCCESS;
  }

  private getPracticePathUuidFromUrl(): string {
    return this.activatedRoute.snapshot.params.practicePathUuid;
  }

  // Ha megváltoznak az ikonok akkor újra határozzuk meg
  // a megjelenítendő ikonok listáját
  addListenerToIconChanges() {
    this.globalPracticePathEditorService.timedIconAssignments$.addListener(() => {
      this.reCalculateDisplayedIconsAtVideoPosition(
        this.globalPracticePathEditorService.mainVideoPlayerController.getLastKnownPosition()
      );
    });
    this.globalPracticePathEditorService.pathIconAssignments$.addListener(() => {
      this.reCalculateDisplayedIconsAtVideoPosition(
        this.globalPracticePathEditorService.mainVideoPlayerController.getLastKnownPosition()
      );
    });
  }

  // Az útvonal ikonokhoz külön controllert használunk
  private initPathIconController() {
    this.globalPracticePathEditorService.pathIconMapController =
      new PathIconMapController(
        this.globalPracticePathEditorService.pathIconAssignments$,
        this.globalPracticePathEditorService.googleMapController.map
      );
  }

  createPipedObservableArrays() {
    this.mappedAssignedCriticalPoints$ =
      this.globalPracticePathEditorService.criticalPointAssignments$.pipe(
        (assignment: CriticalPointAssignment) => {
          return {
            position:
              this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.find(
                (cp) => cp.uuid == assignment.criticalPointUuid
              ).coordinate,
          };
        }
      );

    // Azt akarjuk, hogy a critical points in corresponding city-ből
    // csak azokat tartsuk meg, amik nincsennek hozzárendelve az útvonalhoz
    this.mappedNotAssignedCriticalPoint$ =
      this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.pipe(
        (elem: CriticalPoint) => {
          const isAssigned =
            this.globalPracticePathEditorService.criticalPointAssignments$.find(
              (cp) => cp.criticalPointUuid == elem.uuid
            ) != undefined;
          const isGlobal = !elem.isLocal;

          // A kritikus pont közel van legalább 1 path ponthoz
          // ha nincs közel akkor nincs értelme megjeleníteni
          const isCloseToPath =
            this.practicePathUtil.isCriticalPointCloseToAtLeastOnePathPoint(
              elem,
              this.globalPracticePathEditorService.path$.getOriginalArrayRef()
            );
          // Azokat a kritikus pontokat kiszűri amelyek
          // nincsennek a path közelében ÉS nincsennek hozzárendelve az útvonalhoz
          // Akkor van a path közelében, ha van a pathnek 1 olyan pontja amitől
          // a kritikus pont legfeljebb 70 méterre van

          const isVisibleOnMap =
            isCloseToPath &&
            !isAssigned &&
            (isGlobal ||
              elem.onlyAssignablePracticePathUuid ==
              this.globalPracticePathEditorService.practicePath.uuid);

          return {
            position: isVisibleOnMap
              ? elem.coordinate
              : { latitude: 0, longitude: 0 },
          };
        }
      );

    // Az underlying adatok között átfedés van, ezért újra kell mappelni
    // Az underlying adat nem változik bizonyos esetben a markereknél,
    // viszont a mapping által használt adatok változhatnak. Ekkor habár
    // a forrásunk nem változott, de a transzformációnak a függősége igen

    // Lehetséges esetek:
    // (1) Egy kritikus pont hozzárendelést töröltünk az útvonalról
    // Ekkor a hozzárendelést nem kell frissíteni, onnan eltűnik a marker
    // viszont nem jelenik meg a not assigned markerekben
    // ahhoz, hogy megjelenjen remappelnünk kell azt a kritikus pontot amit levettünk az útvonalról
    // (2) Egy kritikus pont hozzárendelést hozzáadtunk az útvonalhoz
    // ugyanazt kell csinálni, mint az első (1) esetben, a kritikus pontot remappelni kell (hogy eltűnjön a not assigned markerekben)
    // (3) Egy kritikus pontot frissítettünk. (Ez hatással lehet az ASSIGNED kritikus pontokra, hiszen változhatott a pozíciója)
    // tehát remappeljük az assigned-ból azt a hozzárendelést, ami arra a kritikus pontra mutat amit éppen módosítottunk

    // (1) (2)
    this.globalPracticePathEditorService.criticalPointAssignments$.addListener(
      (action, oldValue, newValue, ind) => {
        // Ha változott a kritikus pont hozzárendelés,
        // akkor, ha van aktív kritikus pont aktiváljuk újra
        // hiszen megváltozhatott a fókusz pontja
        // de akár változtatásra is kerülhetett a megállási pont ideje
        if (this.activateStopPoint != null) {
          this.deactivateStopPoint();
          this.lastActivatedStopPoint = null;
          this._onChangeVideoPosition(
            this.globalPracticePathEditorService.mainVideoPlayerController.getLastKnownPosition()
          );
        }

        if (action != "add" && action != "remove") return;
        // Itt az action csak add, vagy remove lehet
        const index =
          this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.findIndex(
            (cp) =>
              cp.uuid ==
              (action == "add"
                ? newValue.criticalPointUuid
                : oldValue.criticalPointUuid) // add esetén new, remove esetén oldValue van
          );
        this.mappedNotAssignedCriticalPoint$.resyncFromSource(index);
      }
    );

    // (3) Egy kritikus pontot frissítettünk a vizsgahelyszínben
    this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.addListener(
      (action, oldValue, newValue, ind) => {
        if (action == "update") {
          const index =
            this.globalPracticePathEditorService.criticalPointAssignments$.findIndex(
              (assignment) => assignment.criticalPointUuid == newValue.uuid
            );
          this.mappedAssignedCriticalPoints$.resyncFromSource(index);
        }
      }
    );
  }

  _addVideoPlayerListeners() {
    this.globalPracticePathEditorService.mainVideoPlayerController
      .getCurrentPositionInMs$()
      .subscribe((newPosition) => {
        this._onChangeVideoPosition(newPosition);
      });

    this.globalPracticePathEditorService.mainVideoPlayerController._playControlClick
      .pipe(first())
      .subscribe(() => {
        // Audio unlock
        // https://stackoverflow.com/questions/67655730/web-audio-api-source-start-only-plays-the-second-time-it-is-called-in-safari
        this.audioPlayer.play("./assets/sounds/start-sound.mp3", 0.0);
      });

    this.globalPracticePathEditorService.mainVideoPlayerController
      .isPlaying()
      .subscribe((val) => {
        // Ha újra elindul a lejátszás, úgy, hogy aktiválva volt egy stop pont
        // akkor ezt a stop pontot deaktiváljuk
        if (val && this.activatedStopPoint) {
          this.deactivateStopPoint();
        }
      });

    this.globalPracticePathEditorService.mainVideoPlayerController
      .getPlaybackSpeed()
      .subscribe((newPlaybackSpeed: number) => {
        if (newPlaybackSpeed == null) return;
        const epsilon = PracticePathUtil.maximumEpsilonTimeDiffOnStopPointMs;
        /**
         * playback speed: 1,2,3
         * 70 ms-en belül kell lennünk
         * és azt kell meghatároznunk, hogy milyen sűrűn updateljük az időt
         * Mi a worst case?
         * (-70 .... x)
         * Ha pont az intervallum előtt checkolok egyet, akkor van a legtöbb esélye, hogy nem leszek benne.
         * tehát ha a check x-(eps+1) történik
         * ekkor minden olyan esetben megállunk, amikor a következő check (eps+1) történik
         * Tehát,,ha eps+1-nél kisebb a time update, akkor minden esetben megállunk
         * Mi történik, ha felgyorsitjuk az időt, és mondjuk 1 ms alatt 2ms haladunk a videóban?
         * Ekkor az intervallumunk lesz kisebb, tehát pl 2x gyorsításnál (eps/2)+1 check kell
         */
        this.globalPracticePathEditorService.mainVideoPlayerController.setTimeUpdateInterval(
          epsilon / newPlaybackSpeed
        );
        if (newPlaybackSpeed >= 4) {
          this.notifier.notify(
            "warning",
            "Túl gyors lejátszási sebesség. Kritikus pontoknál probléma lehet a megállásnál!"
          );
        }
      });

    // Lehet, hogy csak visszább tekert, ilyenkor engedélyezzük ugyanazt a kritikus pont megjelenítést
    // ha nem engednénk, akkor olyan, mintha visszatekerés után hibásan nem állna meg a ponton
    this.globalPracticePathEditorService.mainVideoPlayerController
      .getSeekObservable()
      .subscribe(() => {
        if (this.lastActivatedStopPoint != null) {
          this.lastActivatedStopPoint = null;
          this.deactivateStopPoint();
        }
      });
  }

  getCarOrientationAngleAtVideoPosition(pos: number): number {
    return this.practicePathUtil.getBearingAngleAtVideoPosition(
      this.globalPracticePathEditorService.path$.getOriginalArrayRef(),
      pos
    );
  }

  async _changeCarOrientation(currentVideoPosition: number) {
    const newAngle =
      this.getCarOrientationAngleAtVideoPosition(currentVideoPosition);
    this.carMarkerController.setNewIconStyle({
      defaultIcon: {
        url: await new RotateMarkerIconUtil().rotateIcon(
          "assets/markers/car_top.png",
          newAngle
        ),
        scaledSize: new google.maps.Size(50, 50),
        anchor: new google.maps.Point(25, 25),
      },
      // single marker esetén nincs értelme a kiválasztásnak
      selectedIcon: "",
    });
    this.lastCarOrientationAngle = newAngle;
  }

  // A videó a végére ért
  onEndVideo() {
    // Jelenítsünk meg egy dialógust, amiben leírjuk,
    // hogy ellenőrizze azt, hogy van-e lezáratlan, befejezetlen ikon felvétele
    this.confirmationDialogService.open(
      "A videó végére értél",
      "Ellenőrizd, hogy van-e lezáratlan időzített, vagy útvonal ikon felvételed. ",
      null,
      null,
      "Oké",
      "Bezár"
    );
  }

  // Videó pozíció változás esetén:
  // 1, Frissítsük a car position-t
  // 2, Ellenőrizzük le, hogy meg kell-e állnunk
  //  -> ha nem: continue
  //  -> ha igen: A megállási ponttól függően jelenítsük meg a fókusz pontot
  //              és a kritikus pont szövegét, továbbá állítsuk is le a videót.
  //              A kritikus pont tabon tekerjünk ahhoz a kritikus ponthoz ahol megálltunk a videóban
  //
  lastCarOrientationAngle: number = -1;

  isVideoOnEnd = false;
  async _onChangeVideoPosition(newPositionInMs: number) {
    if (!this.isMapInitDone) return; // Még nincs a map inicializálva
    const totalDuration =
      this.globalPracticePathEditorService.mainVideoPlayerController
        ._totalDurationInMs$.value;
    // A videó a végére ért
    if (
      !this.isVideoOnEnd &&
      newPositionInMs >= (totalDuration - 100) &&
      totalDuration > 0 // Lehet, hogy akkor hívódik meg az onChangeVideoPosition, amikor még a totalDuration nem kapott értéket
    ) {
      console.log("on end video claled");
      this.onEndVideo();
      this.isVideoOnEnd = true;
      return;
    } else {
      if (newPositionInMs < (totalDuration - 100)) {
        this.isVideoOnEnd = false;
      }
    }

    // Itt vagyunk jelenleg (geo location idő alapján)
    const currentPosition =
      this.globalPracticePathEditorService.currentPosition.getValue();

    // Autónak frissítjük az orientációját, ha megváltozott
    if (
      this.lastCarOrientationAngle !=
      this.getCarOrientationAngleAtVideoPosition(newPositionInMs)
    ) {
      this._changeCarOrientation(newPositionInMs);
    }

    // Frissítjük az autó pozíciót, ha megváltozott
    if (
      deepEqual(
        this.carPosition$.getValue().position,
        currentPosition.position
      ) == false
    ) {
      this.carPosition$.next({
        position: {
          latitude: currentPosition.position.latitude,
          longitude: currentPosition.position.longitude,
        },
      });
    }

    // Nézzük meg, hogy meg kell-e állnunk kritikus pontnál
    const stopPoint: null | {
      stopPoint: StopPoint;
      criticalPointAssignment: CriticalPointAssignment;
    } = this.practicePathUtil.getCloseStopPointToVideoPosition(
      this.globalPracticePathEditorService.criticalPointAssignments$.getOriginalArrayRef(),
      newPositionInMs
    );
    if (
      stopPoint != null &&
      this.globalPracticePathEditorService.shouldStopAtCriticalPoint
    ) {
      if (
        this.lastActivatedStopPoint?.stopPoint?.stopPointUuid !=
        stopPoint.stopPoint.stopPointUuid
      ) {
        // Csak akkor állunk meg újra, ha az előzőleg aktivált stop pont nem ez volt
        this.activateStopPoint(
          stopPoint.stopPoint,
          this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.find(
            (cp) =>
              cp.uuid == stopPoint.criticalPointAssignment.criticalPointUuid
          )
        );
        this.globalPracticePathEditorService.mainVideoPlayerController.pause();
        this.lastActivatedStopPoint = this.activatedStopPoint;
      }
    }

    // Határozzuk meg a megjelenítendő ikonokat!
    this.reCalculateDisplayedIconsAtVideoPosition(newPositionInMs);
  }

  reCalculateDisplayedIconsAtVideoPosition(videoPosition: number) {
    const icons = this.practicePathUtil.determineCurrentlyVisibleIcons(
      videoPosition,
      this.globalPracticePathEditorService.path$.getOriginalArrayRef(),
      this.globalPracticePathEditorService.timedIconAssignments$.getOriginalArrayRef(),
      this.globalPracticePathEditorService.pathIconAssignments$.getOriginalArrayRef(),
      this.globalPracticePathEditorService.practiceIcons
    );

    // Szűrjük ki a duplikációkat is
    // és külön tömbe tegyük az indexelést és táblákat
    this.displayedLeftTableIconsUrl = [
      ...new Set(
        icons
          .filter((icon) => icon.name.toLowerCase().includes("index") == false)
          .map((p) => p.iconUrl)
      ),
    ];

    this.displayedTopIndexIconsUrl = [
      ...new Set(
        icons
          .filter((icon) => icon.name.toLowerCase().includes("index") == true)
          .map((p) => p.iconUrl)
      ),
    ];
  }

  // Egy megállási pontot aktivál (kritikus pont szöveg + fókusz pont megjelenítése)
  activateStopPoint(stopPoint: StopPoint, criticalPoint: CriticalPoint) {
    this.activatedStopPoint = {
      criticalPoint: criticalPoint,
      stopPoint: stopPoint,
      index: this.globalPracticePathEditorService.criticalPointAssignments$.findIndex(
        (cp) => cp.criticalPointUuid == criticalPoint.uuid
      ),
    };

    this.globalPracticePathEditorService.currentlyActiveCriticalPoint.next(
      criticalPoint
    );

    // Ha van audio játszuk le!
    if (
      criticalPoint.audio?.narrationForDescriptionUrl != null &&
      this.globalPracticePathEditorService.isNarrationEnabled
    ) {
      this.audioPlayer.play(criticalPoint.audio?.narrationForDescriptionUrl);
    }
  }

  // Deaktivál egy stop pontot
  deactivateStopPoint() {
    this.activatedStopPoint = null;
    this.audioPlayer.cancelCurrentPlaying();
    this.globalPracticePathEditorService.currentlyActiveCriticalPoint.next(null);
  }

  onTapGoogleMap(tapPosition: LatLng) {
    const closestGeoTaggedFrame =
      this.practicePathUtil.getClosestGeoTaggedFrameToGeoLocation(
        this.globalPracticePathEditorService.path$.getOriginalArrayRef(),
        tapPosition
      );

    // ha nemrég történt deselecting akkor nem seekelünk
    // hiszen akkor azért kattintott a térképre, hogy deselectelje
    // a kiválasztott markereket (A marker event listenere a mapen hamarabb fut le, mint ez)
    const isMarkerDeselectingHappenNow =
      Date.now() -
      this.markeredPolylineControllerForPath.lastMarkerDeselectingTimestamp <
      200;

    if (
      this.markeredPolylineControllerForPath.isMarkerVisible == false ||
      !isMarkerDeselectingHappenNow
    ) {
      this.globalPracticePathEditorService.mainVideoPlayerController.seekTo(
        closestGeoTaggedFrame.millisecOfFrame
      );
    }
  }

  _initPathMarkers() {
    const pathMarkerSize = new google.maps.Size(25, 25);
    const pathMarkerAnchor = new google.maps.Point(13, 13);

    const globalPathStyle = MarkerUtil.getCircleMarker(
      "orange",
      "blue",
      undefined,
      pathMarkerSize,
      pathMarkerAnchor
    );

    this.googleMapController.addMarkeredPolylineController(
      (this.markeredPolylineControllerForPath = new MarkeredPolylineController(
        this.globalPracticePathEditorService.path$,
        {
          id: "orange_path",
          isDraggableMarkers: true,
          isMultiSelectable: true,
          polylineColor: "orange",
          displayedName: "Útvonal",
          showFirstAndLast: true,
          clickable: true,
          markerIconStyles: {
            firstMarkerStyle: MarkerUtil.getCircleMarker(
              "black",
              "blue",
              undefined,
              pathMarkerSize,
              pathMarkerAnchor
            ),
            lastMarkerStyle: MarkerUtil.getCircleMarker(
              "green",
              "blue",
              undefined,
              pathMarkerSize,
              pathMarkerAnchor
            ),
            globalStyle: globalPathStyle,
            specificMarkerStyles: [],
          },
          maximumVisibleMarkerOnMap: 80,
          zIndex: 0,
        }
      ))
    );

    // default ne látszódjanak a sárga pöttyök
    this.markeredPolylineControllerForPath.setMarkersVisibility(false);
    this.markeredPolylineControllerForPath.onMarkerVisibilityChange.subscribe(
      (newValue: boolean) => {
        if (newValue)
          this.notifier.notify(
            "warning",
            "Ha szeretnéd elmenteni a sárga pöttyöket kattints majd az 'Útvonal pontok mentése' gombra"
          );
      }
    );

    this.markeredPolylineControllerForPath.onRightClickMarker.subscribe(
      (markerClickEvent: MarkerClickEvent) => {
        console.log(markerClickEvent.underlyingMarkerIndex);

        this.showInfoWindowForPathItem(
          this.globalPracticePathEditorService.path$.getAt(
            markerClickEvent.underlyingMarkerIndex
          ),
          markerClickEvent.underlyingRenderedMarker,
          markerClickEvent.underlyingMarkerIndex
        );
      }
    );
  }

  // Sárga pöttyhöz jobb klikkre info window
  async showInfoWindowForPathItem(
    pathItem: PathItem,
    underlyingRenderedMarker: google.maps.Marker,
    underlyingMarkerIndex: number
  ) {
    const timeStr = new MsToTimeStringPipe().transform(
      pathItem.millisecOfFrame
    );

    const infowindow = new google.maps.InfoWindow({
      content: underlyingMarkerIndex.toString() + ": " + timeStr,
    });

    infowindow.open({
      map: this.googleMapController.map,
      anchor: underlyingRenderedMarker,
    });
  }


  async _initCarMarker() {
    this.carPosition$.next(this.globalPracticePathEditorService.path$.getCopyAt(0));
    this.googleMapController.addSingleMarkerController(
      (this.carMarkerController = new SingleMarkerController({
        underlyingMarker: this.carPosition$,
        clickable: false,
        iconStyle: {
          defaultIcon: {
            url: await new RotateMarkerIconUtil().rotateIcon(
              "assets/markers/car_top.png",
              this.practicePathUtil.getBearingAngleAtVideoPosition(
                this.globalPracticePathEditorService.path$.getOriginalArrayRef(),
                0
              )
            ),
            scaledSize: new google.maps.Size(50, 50),
            anchor: new google.maps.Point(25, 25),
          },
          // single marker esetén nincs értelme a kiválasztásnak
          selectedIcon: "",
        },
        isDraggable: false,
        shouldMapCenterFollowMarker: false,
        zIndex: 1,
      }))
    );
    this.carMarkerController.setMarkerFollowEnabled(true);
    this.carMarkerController.setOpacity(0.8);
  }

  async _initAssignedCriticalPointMarkers() {
    this.markeredPolylineForAssignedCriticalPoint =
      new MarkeredPolylineController(this.mappedAssignedCriticalPoints$, {
        isDraggableMarkers: false,
        isMultiSelectable: false,
        maximumVisibleMarkerOnMap: 40,
        displayedName: "Hozzárendelt kritikus p.",
        id: "assignedCriticalPoints",
        zIndex: 4,
        minZoom: 1,
        markerIconStyles: {
          globalStyle: MarkerUtil.getLabeledMarker("red", "red", "#"),
          specificMarkerStyles: [...Array(200).keys()].map((i) => {
            return {
              markerIndex: i,
              style: MarkerUtil.getLabeledMarker(
                "#ff7263",
                "red",
                (i + 1).toString()
              ),
            };
          }),
        },
      });

    this.markeredPolylineForAssignedCriticalPoint.setPolylineVisibility(false);
    this.googleMapController.addMarkeredPolylineController(
      this.markeredPolylineForAssignedCriticalPoint
    );

    this.markeredPolylineForAssignedCriticalPoint.onLeftClickMarker
      .pipe(skipWhile((m) => m == null))
      .subscribe((tapEvent) => {
        this.googleMapController.exitFullscreen();
        this.zone.run(() => {
          console.log(tapEvent);
          this.onTapAssignedCriticalPointMarker(tapEvent);
        });
      });

    this.markeredPolylineForAssignedCriticalPoint.onRightClickMarker.subscribe(
      (event) => {
        if (event == null) return;
        this.zone.run(() => {
          const assignment =
            this.globalPracticePathEditorService.criticalPointAssignments$.getAt(
              event.underlyingMarkerIndex
            );
          const criticalPoint =
            this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.find(
              (cp) => cp.uuid == assignment.criticalPointUuid
            );

          const menuForClick = new MenuWindowOverlay(
            new google.maps.LatLng(
              event.position.latitude,
              event.position.longitude
            ),
            criticalPoint.title,
            criticalPoint.description +
            "\n" +
            assignment.stopPoints
              .map((sp) => {
                const time = new MsToTimeStringPipe().transform(sp.stopTimeInMs);
                const isActive = sp.isActive;
                return `${time} ${isActive ? '✅' : '🔴'}`
              })
              .join("\n"),
            [
              {
                text: "Kritikus pont levétele",
                backgroundColor: "red",
              },
            ],

            (optionIndex: number) => {
              if (optionIndex == 0) {
                this.globalPracticePathEditorService.removeCriticalPointAssignment(
                  assignment.uuid,
                  criticalPoint
                );
              }
            },
            this.zone
          );
          this.googleMapController.showMenu(menuForClick);
        });

        // Dragnél vagy bármilyen map eventnél az overlayt bezárjuk!
      }
    );
  }

  async onTapAssignedCriticalPointMarker(event: MarkerClickEvent) {
    const assignment =
      this.globalPracticePathEditorService.criticalPointAssignments$.getCopyAt(
        event.underlyingMarkerIndex
      );
    // erre a kritikus pontra kattintottunk
    const tappedCriticalPoint =
      this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$
        .getOriginalArrayRef()
        .find((cp) => cp.uuid === assignment.criticalPointUuid);
    this.globalPracticePathEditorService.openCriticalPointEditorAndHandleSave(
      tappedCriticalPoint,
      assignment,
      this.viewContainerRef,
      this.injector
    );
  }

  // A nem hozzárendelt kritikus pontokhoz marker inicializálás
  // Ezek a pontok jobb klikkel felvehetőek lesznek
  async _initNotAssignedCriticalPointMarkers() {
    this.markeredPolylineForNotAssignedCriticalPoints =
      new MarkeredPolylineController(this.mappedNotAssignedCriticalPoint$, {
        isDraggableMarkers: false,
        isMultiSelectable: false,
        clickable: true,
        maximumVisibleMarkerOnMap: 70,
        displayedName: "Nem hozzárendelt kritikus p.",
        id: "notAssignedCriticalPoints",
        zIndex: 3,
        minZoom: 1,
        markerIconStyles: {
          globalStyle: MarkerUtil.getNotAssignedCriticalPointMarker(),
          specificMarkerStyles: [],
        },
        showFirstAndLast: false,
      });

    this.markeredPolylineForNotAssignedCriticalPoints
      .setPolylineVisibility(false)
      .setMarkerOpacity(0.65);

    this.googleMapController.addMarkeredPolylineController(
      this.markeredPolylineForNotAssignedCriticalPoints
    );

    // Jobb klikkel egy nem felvett kritikus pontra
    // megnyit egy ablakot overlay-t a térképen ami leírja a kritikus pontot
    // és biztosít egy felvesz gombot
    this.markeredPolylineForNotAssignedCriticalPoints.onRightClickMarker.subscribe(
      (event) => {
        if (event == null) return;
        const criticalPoint =
          this.globalPracticePathEditorService.criticalPointsInCorrespondingCity$.getAt(
            event.underlyingMarkerIndex
          );

        const menuForClick = new MenuWindowOverlay(
          new google.maps.LatLng(
            event.position.latitude,
            event.position.longitude
          ),
          criticalPoint.title,
          criticalPoint.description,
          [
            {
              text: "Kritikus pont felvétele",
            },
          ],

          (optionIndex: number) => {
            if (optionIndex == 0) {
              this.globalPracticePathEditorService.assignExistedCriticalPoint(
                criticalPoint.uuid
              );
            }
          },
          this.zone
        );
        this.googleMapController.showMenu(menuForClick);
        // Dragnél vagy bármilyen map eventnél az overlayt bezárjuk!
      }
    );
  }

  setMapCenterToCriticalPoint(criticalPoint: CriticalPoint) {
    this.googleMapController.setCenter(criticalPoint.coordinate);
  }

  async initMarkers() {
    this._initPathMarkers();
    // Mivel az initMarkers egy gyerek komponens (googleMapExtended)
    // ngAfterVieWInit metódusából van hívva, ezért ha nem futtatnánk explicit változást
    // akkor nem renderelődne újra (a következő detektálásig) az initPathMarkers hatására megváltozott adat
    this.changeDetector.detectChanges();

    await this._initCarMarker();
    await this._initAssignedCriticalPointMarkers();
    await this._initNotAssignedCriticalPointMarkers();
  }

  addGeneralEventListeners() {
    const disableShortcut = () => {
      this.isKeyboardShortcutEnabled = false;
    }

    const enableShortcut = () => {
      this.isKeyboardShortcutEnabled = true;
    }

    // Ha meg van nyitva a kritikus pont szerkesztő, akkor kikapcsoljuk a shortcut-ot
    this.globalPracticePathEditorService.generalEventEmitter.addListener("criticalPointEditorOpened", disableShortcut);
    this.globalPracticePathEditorService.generalEventEmitter.addListener("criticalPointEditorClosed", enableShortcut);
    this.globalPracticePathEditorService.generalEventEmitter.addListener("commentsDialogClosed", enableShortcut);
    this.globalPracticePathEditorService.generalEventEmitter.addListener("commentsDialogOpened", disableShortcut);
  }


  @HostListener('document:keypress', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {
    // SNAP TO ROAD
    if (event.key.toLowerCase() == 'f') {
      const selectedMarkerIndexes = this.markeredPolylineControllerForPath.getSelectedMarkerIndexes();
      const targetMarkerPositions: GoogleMapPosition[] = [];
      for (const ind of selectedMarkerIndexes) {
        const position = this.globalPracticePathEditorService.path$.getCopyAt(ind);
        targetMarkerPositions.push({ latitude: position.position.latitude, longitude: position.position.longitude });
      }

      const snapped = await this.snapToRoadService.snapping(targetMarkerPositions);
      for (let i = 0; i < selectedMarkerIndexes.length; i++) {
        const ind = selectedMarkerIndexes[i];
        const position = this.globalPracticePathEditorService.path$.getCopyAt(ind);
        position.position.latitude = snapped[i].latitude;
        position.position.longitude = snapped[i].longitude;
        this.globalPracticePathEditorService.path$.updateAt(ind, position);
      }
    }

    // Ha van kijelölés akkor az utoljára mentett path alapján visszaállítja a kijelölt path pontokat
    if (event.key.toLowerCase() == 'z') {
      this.globalPracticePathEditorService.recoverySpecificPathPointsFromLastSaved(this.markeredPolylineControllerForPath.getSelectedMarkerIndexes())
    }

    if (event.key.toLowerCase() == 'w') {
      if (this.googleMapController.getZoomLevel() == 22) {
        if (this.lastZoomLevelBeforeWZoom == null) {
          // corner case
          this.googleMapController.setZoomLevel(19);
          return;
        }
        this.googleMapController.setZoomLevel(this.lastZoomLevelBeforeWZoom);
      } else {
        this.lastZoomLevelBeforeWZoom = this.googleMapController.getZoomLevel();
        console.log(this.lastZoomLevelBeforeWZoom);
        this.googleMapController.setZoomLevel(22);

        this.googleMapController.setCenter(this.googleMapController.lastLatLngPositionOfCursor.getValue());
      }
    }
  }


  // Amikor a térkép inicializálódott ezt a callbacket hívja:
  async onMapInited(googleMapController: GoogleMapExtendedController) {
    this.googleMapController = googleMapController;
    this.googleMapController.setCenter(
      this.globalPracticePathEditorService.path$.getAt(0).position
    );

    this.globalPracticePathEditorService.googleMapController =
      this.googleMapController;

    await this.initMarkers();
    this.googleMapController.onTap.subscribe((tapPosi) => {
      this.onTapGoogleMap(tapPosi);
    });

    this.initPathIconController();
    this.isMapInitDone = true;
  }

  selectedMatTabChanged(event: MatTabChangeEvent) {
    this.tabScrollPositionRecoveryService.onMatTabChange.next(event);
  }

  onSplitViewChange(event: any) {
    this.practiceSplitViewService.save(event.sizes[0], event.sizes[1]); 
  }

  // Sárga útvonal pontok mentése
  async onTapSaveOrangePathPoints() { 
    await this.globalPracticePathEditorService.updatePath(
      this.globalPracticePathEditorService.path$.getFullCopy()
    );

    // Mivel változott a path, ezért lehet, hogy bizonyos NEM felvett
    // kritikus pontok kiesnek (mert távolabbra kerültek, mint 50 méter)
    // és lehet, hogy bizonyos pontok bekerülnek, mivel lett olyan path pont ami a közelébe került
    // ezért újra kell renderelni a nem felvett kritikus pont markereket
    // Mivel egy pipeolt observable array alapján renderelődnek, ezért újra kell transzformálni
    // a teljes szülő array-t, mivel a tömb nem változott, de az adatko maga a transzformációban igen
    // ez az adat a path, ami alapján eldöntjük, hogy az adott kritikus pont látszódjon vagy ne
    for (let i = 0; i < this.mappedNotAssignedCriticalPoint$.length; i++) {
      this.mappedNotAssignedCriticalPoint$.resyncFromSource(i);
    }
  }
}
