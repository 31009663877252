import { PracticePathVideoCutterDialogComponent } from './../practice-path-video-cutter-dialog/practice-path-video-cutter-dialog.component';
import { ConfirmationDialogService } from "src/app/modules/confirmation-dialog/services/confirmation-dialog.service";
import { PracticePathNodeTouchesEditorDialogComponent, PracticePathNodeTouchesEditorDialogData } from "./components/practice-path-node-touches-editor-dialog/practice-path-node-touches-editor-dialog.component";
import { PracticePathMainDataEditorComponent } from "./components/practice-path-main-data-editor/practice-path-main-data-editor.component";
import { PracticeCity, PracticeCityBaseInformations, practiceCityBaseInformationsFields } from "src/app/classes/model/practice-city";
import { PracticeCityService } from "src/app/services/practice-city.service";
import { NotifierService } from "angular-notifier";
import { PracticePath, PracticePathBaseInformations } from "src/app/classes/model/practice-path";
import { PracticePathService } from "src/app/services/practice-path.service";
import { EntityAccessCheckService } from "src/app/services/entity-access-check.service";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PracticeVideoManagerDialogComponent, PracticeVideoManagerDialogData } from "../practice-video-manager-dialog/practice-video-manager-dialog.component";
import { Permission } from "src/app/classes/model/permissions";
import { PracticePathGlobalVideoEditorPageService } from "src/app/modules/practice-path-video-editor-page/services/practice-path-global-video-editor-page.service";
import { Subscription } from "rxjs";
import { PermissionService } from 'src/app/services/common/permission.service';

// Fő adatok szerkesztésére szolgáló oldal
// Fő adat például: Név, Vizsgahelyszín, melyik zónában van benne, stb...
// Itt lehet továbbá az új videó feltöltést is elvégezni

@Component({
  selector: "app-practice-path-main-data-editor-page",
  templateUrl: "./practice-path-main-data-editor-page.component.html",
  styleUrls: ["./practice-path-main-data-editor-page.component.scss"],
  providers: [
    PracticePathGlobalVideoEditorPageService
  ]
})
export class PracticePathMainDataEditorPageComponent implements OnInit {
  @ViewChild(PracticePathMainDataEditorComponent)
  mainDataEditor: PracticePathMainDataEditorComponent;

  practicePath: PracticePath|null = null;
  currentCity: PracticeCity;

  InitState:typeof InitState = InitState;
  initState:InitState;

  readonly defaultErrorMessage:string = "Az útvonal betöltése nem sikerült. Próbáld újra, vagy írj az info@mrkresz.hu címre";

  userCanConvertVideos:boolean = false;

  numberOfNonVerifiedCriticalPoints:number = 0;
  numberOfCriticalPointsWithoutStopPoint:number = 0;

  practiceCitiesBaseInformations:Array<PracticeCityBaseInformations> = [];

  constructor(
    private practicePathService: PracticePathService,
    private activatedRoute: ActivatedRoute,
    private routerService: Router,
    private notifier: NotifierService,
    private practiceCityService: PracticeCityService,
    private dialogService: MatDialog,
    private confirmationService: ConfirmationDialogService,
    private entityAccessCheckService:EntityAccessCheckService,
    private permissionService:PermissionService,
    private viewContainerRef:ViewContainerRef,
    protected practicePathGlobalVideoEditorPageService:PracticePathGlobalVideoEditorPageService,
  ) {}

  ngOnInit(): void {
    // Get if the user has the practice video convert permission
    this.userCanConvertVideos = this.permissionService.isLoggedUserHasPermission(Permission.PracticeVideoConvert);

    this.initialize();
  }

  protected async initialize():Promise<void> {
    // Set the init state to loading
    this.initState = InitState.LOADING;

    try {
      await this.loadPracticePathCitiesBaseInformations();
      await this.initEditedPracticePath();
    } catch(error:any) {
      if((error as InitError).initState != undefined) {
        this.initState = error.initState;
      } else {
        this.initState = InitState.ERROR;
      }
      return;
    }

    this.initState = InitState.SUCCESS;
  }

  private async loadPracticePathCitiesBaseInformations():Promise<void> {
    try {
      this.practiceCitiesBaseInformations = await this.practiceCityService.fetchPracticeCities(
        practiceCityBaseInformationsFields
      ) as Array<PracticeCityBaseInformations>;
    } catch(error:any) {
      throw new InitError(InitState.CITIES_LOADING_ERROR);
    }
  };

  /**
   * Initializes the edited practice path. It cheks that the edited practice path exists and
   * the admin user has permission to edit it.
   */
  private async initEditedPracticePath():Promise<void> {
    // Get the edited practice path's uuid from the URL params
    const targetPathUuid:string = this.activatedRoute.snapshot.params.practicePathUuid;
    try{
      // Check if the admin has access to the practice path
      const hasAccess = await this.entityAccessCheckService.checkEntityAccess(
        "practice_path",
        {
          practicePathUuid: targetPathUuid
        }
      );
      if(!hasAccess){
        // If he/she has no acces throw the init state no access error.
        throw new InitError(InitState.NO_ACCESS);
      }
    }catch(error:any){
      // If an exception happened during the access check, handle the error
      this.throwEntityRelatedInitError(error, "path");
      return;
    }

    const cityUuid:string = this.activatedRoute.snapshot.params.cityUuid;
    try {
      // Load the city
      this.currentCity = await this.practiceCityService.fetchPracticeCity(cityUuid);
    } catch(error:any) {
      // If an exception happened during the city or the practice path loading, handle the error
      this.throwEntityRelatedInitError(error, "city");
      return;
    }

    try {
      // Load the edited practice path
      this.practicePath = await this.practicePathService.fetchPracticePath(targetPathUuid);
    } catch(error:any) {
      // If an exception happened during the city or the practice path loading, handle the error
      this.throwEntityRelatedInitError(error, "path");
      return;
    }

    // Set the number of the non-verified critical points
    this.numberOfNonVerifiedCriticalPoints = this.practicePathService.getNumberOfNonVerifiedCriticalPointsOfPath(
      this.currentCity,
      this.practicePath
    );
    this.numberOfCriticalPointsWithoutStopPoint = this.practicePathService.getNumberOfCriticalPointsWithoutStopPointOfPath(this.practicePath);
  }

  private throwEntityRelatedInitError(error:any, entityType:"city"|"path"):void {
    // Check if the entity was not found
    if(error.error?.error == 'NOT_FOUND_ENTITY'){
      switch(entityType){
        case "city":
          throw new InitError(InitState.NOT_EXIST_CITY);
        case "path":
          throw new InitError(InitState.NOT_EXIST_PATH);
        default:
          throw new InitError(InitState.ERROR);
      }
    } else {
      // In other cases show the default error message
      throw new InitError(InitState.ERROR);
    }
  }

  async onTapSave() {
    if (!this.mainDataEditor.isValidForm()) {
      this.notifier.notify("error", "Hibásan megadott mezők");
      return;
    }
    const data = this.mainDataEditor.getDataFromForm();

    this.practicePath = await this.practicePathService.modifyPracticePath(
      this.practicePath.uuid,
      {
        editState: data.editState,
        isFullPracticePath: data.isFullPracticePath,
        examPathNumber: data.examPathNumber,
        name: data.practicePathName,
        zoneUuids: data.zoneUuids,
        isFree: data.isFree,
      }
    );

    this.notifier.notify("success", "Sikeres mentés");
  }

  onTapGoToVideoEditor() {
    this.routerService.navigate(["..", "video-edit"], { relativeTo: this.activatedRoute });
  }

  onTapVideoCut(){
    PracticePathVideoCutterDialogComponent.openDialog(this.dialogService,{
      practicePath: this.practicePath,
      city: this.currentCity
    });
  }

  onTapEditNodeTouchesTime() {
    if (this.currentCity == undefined) {
      this.notifier.notify(
        "error",
        "Az útvonalhoz rendelt vizsgahelyszín nem található"
      );
      return;
    }

    const dialogRef:MatDialogRef<PracticePathNodeTouchesEditorDialogComponent, PracticePath|undefined> = this.dialogService.open<
      PracticePathNodeTouchesEditorDialogComponent,
      PracticePathNodeTouchesEditorDialogData
    >(
      PracticePathNodeTouchesEditorDialogComponent,
      {
        data: {
          practicePath: this.practicePath,
          city: this.currentCity,
        },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      (updatedPracticePath:PracticePath|undefined) => {
        if(updatedPracticePath) {
          this.practicePath.nodeTouches = updatedPracticePath.nodeTouches;
        }
      }
    );
  }

  // Ellenőrzi, hogy ha teljes útvonal, akkor a nodeTouches-nál meg van-e adva
  // az összes szükséges field. Ez azt jelenti, hogy a hosszának meg kell egyeznie
  // a vizsgahelyszínben definiált hosszal, illetve nem lehet egynél több 0ms érintés
  isNodeTouchesMissing() {
    if (this.practicePath.isFullPracticePath == false) return false;

    if (
      this.practicePath.examPathNumber == null ||
      this.practicePath.nodeTouches == null
    )
      return true;

    // A tömb hossza nem egyenlő, valami hiányzik
    if (
      this.practicePath.nodeTouches.length !=
      this.currentCity.cityNodeUuidsAssigmentToExamThesis[
        this.practicePath.examPathNumber
      ].length
    ) {
      return true;
    }

    // Nem lehet egynél több zero érintés
    let countZeroMs = 0;
    for (const touch of this.practicePath.nodeTouches) {
      if (touch.touchMs == 0) countZeroMs++;
    }
    if (countZeroMs > 1) return true;

    return false;
  }

  // Útvonal törlése
  async onTapDelete() {
    this.confirmationService.open(
      "Útvonal végleges törlése",
      "Biztosan törölni akarod VÉGLEGESEN az útvonalat?",
      async () => {
        try {
          await this.practicePathService.deletePracticePath(this.practicePath.uuid);
          this.notifier.notify("success", "Sikeresen törölted az útvonalat!");
          await this.navigateToPracticePathsList();
        } catch (e) {
          this.notifier.notify("error", "Törlés nem sikerült!");
        }
      }
    );
  }

  async navigateToPracticePathsList():Promise<void> {
    try {
      await this.routerService.navigate(["../.."], { relativeTo: this.activatedRoute });
    } catch(error:any) {
      console.error(error);
    }
  }

  protected openVideoManagerDialog():void {
    const dialogRef:MatDialogRef<PracticeVideoManagerDialogComponent, PracticePathBaseInformations> = this.dialogService.open<
      PracticeVideoManagerDialogComponent,
      PracticeVideoManagerDialogData
    >(
      PracticeVideoManagerDialogComponent,
      {
        data: {
          practicePathUuid: this.practicePath.uuid
        },
        disableClose: true,
        viewContainerRef: this.viewContainerRef,
      }
    );

    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (practicePath:PracticePathBaseInformations) => {
        this.practicePath.video = practicePath.video;
        dialogCloseSubscription.unsubscribe();
      }
    )

  }
}

export enum InitState {
  LOADING,
  ERROR,
  CITIES_LOADING_ERROR,
  NOT_EXIST_CITY,
  NO_ACCESS,
  NOT_EXIST_PATH,
  SUCCESS
}

class InitError {
  initState:InitState;

  constructor(initState:InitState) {
    this.initState = initState;
  }
}
