import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, combineLatestWith, filter, map, Observable, take } from 'rxjs';
import { PracticeCity } from 'src/app/classes/model/practice-city';
import { PracticePathBaseInformations } from 'src/app/classes/model/practice-path';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { NavigationQueueService } from 'src/app/services/common/navigation-queue.service';
import { PracticeCityService } from 'src/app/services/practice-city.service';
import { PracticePathService } from 'src/app/services/practice-path.service';
import { PracticePathFiltering } from '../practice-path-filter/practice-path-filter.component';
import { PracticePathSorter } from '../practice-path-sorting-selector/practice-path-sortings';

@Injectable()
export class PracticePathsPageDataService {
  private readonly practicePathBaseInformationsSubject:BehaviorSubject<Array<PracticePathBaseInformations>|null>;
  private readonly isPracticePathDataLoadingSubject:BehaviorSubject<boolean>;

  private readonly practiceCityNamesAndResponsiblesSubject:BehaviorSubject<Array<PracticeCityNameAndResponsibles>|null>;

  private selectedCity:PracticeCity|null;
  private readonly selectedCityLoadingSubject:BehaviorSubject<boolean>;

  private readonly practicePathFilteringSubject:BehaviorSubject<PracticePathFiltering|null>;

  private readonly practicePathBaseInformationsSorterSubject:BehaviorSubject<PracticePathSorter|null>;

  constructor(
    private practicePathService:PracticePathService,
    private practiceCityService:PracticeCityService,
    private navigationQueueService:NavigationQueueService,
    private activatedRoute:ActivatedRoute,
    private notifierService:NotifierService
  ) {
    this.practicePathBaseInformationsSubject = new BehaviorSubject<Array<PracticePathBaseInformations>|null>(null);
    this.isPracticePathDataLoadingSubject = new BehaviorSubject<boolean>(true);

    this.practiceCityNamesAndResponsiblesSubject = new BehaviorSubject<Array<PracticeCityNameAndResponsibles>|null>(null);

    this.selectedCity = null;
    this.selectedCityLoadingSubject = new BehaviorSubject<boolean>(true);

    this.practicePathFilteringSubject =  new BehaviorSubject<PracticePathFiltering|null>(null);

    this.practicePathBaseInformationsSorterSubject = new BehaviorSubject<PracticePathSorter|null>(null);
  }

  /**
   * Initializes the service. It fetches the available cities (their names) for the requester admin and fetches the full
   * initial city from the session service or from the URL's query params.
   */
  public async initialize():Promise<void> {
    // Fetch the available practice city names
    try {
      const practiceCityNamesAndResponsibles = await this.practiceCityService.fetchPracticeCities(
        practiceCityNameAndResponsiblesFields
      ) as Array<PracticeCityNameAndResponsibles>;
      this.practiceCityNamesAndResponsiblesSubject.next(practiceCityNamesAndResponsibles);
    } catch(error:any) {
      throw new PracticePageInitializationError(PracticePageInitializationState.ErrorDuringFetchingCityNames, error);
    }

    // If the array's length is 0, that means the admin is not assigned to any practice path city as content responsible
    // so he/she can't use this page
    if(this.practiceCityNamesAndResponsiblesSubject.value.length === 0) {
      throw new PracticePageInitializationError(PracticePageInitializationState.AdminIsNotAssignedToAnyCityError);
    }

    // Get the inital cityUuid from the route params
    const cityUuid:string = await this.getInitialCityUuid();

    // Fetch and update the actually selected
    try {
      await this.updateSelectedCity(cityUuid);
    } catch(error:any) {
      if(error?.error?.error === "NOT_CONTENT_RESPONSIBLE_OF_CITY") {
        throw new PracticePageInitializationError(PracticePageInitializationState.AdminIsNotAssignedToCityError, error);
      } else {
        throw new PracticePageInitializationError(PracticePageInitializationState.ErrorDuringFetchingCityDetails, error);
      }
    }

    await this.updateCityUuidInParams(cityUuid);
  }

  /**
   * Returns an observable of the filtered and sorted practice path base informations array.
   */
   public get filteredPracticePathBaseInformations$():Observable<Array<PracticePathBaseInformations>|null> {
    // The practicePathBaseInformationsSubject depends on the paths of the city, filtering and the sorting
    // If any of the streams is changed, a new value will be emitted on the output stream

    return this.practicePathBaseInformationsSubject.pipe(
      combineLatestWith(this.practicePathFilteringSubject),
      combineLatestWith(this.practicePathBaseInformationsSorterSubject),
      map(([[practicePathBaseInformations, practicePathFiltering], practicePathBaseInformationSorter]) => {
        // If the actual array is null, return null
        if(practicePathBaseInformations === null) {
          return null;
        }

        // If there is a filtering, filter the practice path informations
        if(practicePathFiltering) {
          practicePathBaseInformations = practicePathBaseInformations.filter(
            this.getPracticePathBaseInformationsFilter(practicePathFiltering)
          );
        }

        // If there is a sorting, sort the practice path informations
        if(practicePathBaseInformationSorter) {
          practicePathBaseInformations.sort(practicePathBaseInformationSorter);
        }

        return practicePathBaseInformations;
      })
    );
  }

  public getLastFilteredPracticePathBaseInformations():Array<PracticePathBaseInformations>|null{
    let lastValue:Array<PracticePathBaseInformations>|null = null;

    // Ezek a műveletek sync lefutnak garantáltan
    this.filteredPracticePathBaseInformations$.pipe(take(1)).subscribe(value => {
      lastValue = value;
    });

    return lastValue;
  }


  /**
   * Returns an obserable which tell if there is a data loading for the pactice path base informations subject.
   */
  public get isPracticePathDataLoading$():Observable<boolean> {
    return this.isPracticePathDataLoadingSubject.asObservable();
  }

  /**
   * Gets the practice path filtering subject as an observable.
   */
  public get practicePathFiltering$():Observable<PracticePathFiltering|null> {
    return this.practicePathFilteringSubject.asObservable();
  }

  /**
   * Gets the practice path sorter subject as an observable.
   */
  public get practicePathBaseInformationsSorter$():Observable<PracticePathSorter|null> {
    return this.practicePathBaseInformationsSorterSubject.asObservable();
  }

  /**
   * Gets the practice city names subject as an observable.
   */
  public get practiceCityNamesAndResponsibles$():Observable<Array<PracticeCityNameAndResponsibles>|null> {
    return this.practiceCityNamesAndResponsiblesSubject.asObservable();
  }

  public get selectedCityLoading$():Observable<boolean> {
    return this.selectedCityLoadingSubject.asObservable();
  }

  /**
   * @returns the actually selected city
   */
  public getSelectedCity():PracticeCity|null {
    return this.selectedCity;
  }

  /**
   * @returns the actual practice path filtering if there is any, otherwise null
   */
  public getPracticePathFiltering():PracticePathFiltering|null {
    return this.practicePathFilteringSubject.value;
  }

  /**
   * @returns the actual practice path sorter function if there is any, otherwise null
   */
  public getPracticePathBaseInformationsSorter():PracticePathSorter|null {
    return this.practicePathBaseInformationsSorterSubject.value;
  }

  /**
   * Updates the actual filtering for the practice path base informations. It also triggers the
   * `filteredPracticePathBaseInformations` recalculation based on the fresh filtering.
   *
   * @param practicePathFiltering the filtering for the practice paths
   */
  public async updatePracticePathFiltering(practicePathFiltering:PracticePathFiltering):Promise<void> {

    if(practicePathFiltering.cityUuid !== this.selectedCity.uuid) {
      await this.updateSelectedCity(practicePathFiltering.cityUuid);
    } else {
      this.practicePathFilteringSubject.next(practicePathFiltering);
    }
  }

  /**
   * Updates the actual sorting for the practice path base informations. It also triggers the
   * `filteredPracticePathBaseInformations` recalculation based on the fresh sorting function.
   *
   * @param practicePathBaseInformationsSorter
   */
  public updatePracticePathSorting(practicePathBaseInformationsSorter:PracticePathSorter) {
    this.practicePathBaseInformationsSorterSubject.next(practicePathBaseInformationsSorter);
  }

  /**
   * Updates the selected city via fetching it from the server. It also triggers the loading of the target city's
   * practice paths.
   *
   * @param cityUuid the uuid of the city
   */
  public async updateSelectedCity(cityUuid:string):Promise<void> {
    this.selectedCityLoadingSubject.next(true);
    try {
      const practiceCity:PracticeCity = await this.practiceCityService.fetchPracticeCity(cityUuid);
      this.selectedCity = practiceCity;
    } catch(error:any) {
      this.notifierService.notify("error", "Hiba a kiválasztott viszgahelyszín betöltésekor!");
      console.error(error);
      throw error;
    } finally {
      this.selectedCityLoadingSubject.next(false);
    }

    await this.loadPracticePathsOfCity(cityUuid);
  }

  /**
   * Loads the practice path base informations of the target city. It also sets the `isPracticePathDataLoadingSubject`
   * to indicate the data loading start and end (regardless the result).
   *
   * @param cityUuid
   */
  public async loadPracticePathsOfCity(cityUuid:string):Promise<void> {
    this.isPracticePathDataLoadingSubject.next(true);

    try {
      const practicePathBaseInformations:Array<PracticePathBaseInformations> =
        await this.practicePathService.fetchPracticePathsOfCity(
          cityUuid,
          "summary"
        );
      this.practicePathBaseInformationsSubject.next(practicePathBaseInformations);
    } catch(error:any) {
      console.error(error);
    }

    this.isPracticePathDataLoadingSubject.next(false);
  }

  /**
   * Callback function to determine which paths fullfills constraints of the actual filter.
   *
   * @param practicePathBaseInformations the practice path base information object to test
   *
   * @returns true if the path fullfills constraints of the actual filter, false otherwise
   */

  private getPracticePathBaseInformationsFilter(filtering:PracticePathFiltering) {
    return (practicePathBaseInformations:PracticePathBaseInformations) => {
      // Check if the practice path type is correct (full or short)
      if(filtering.onlyFullPracticePaths !== practicePathBaseInformations.isFullPracticePath) {
        return false;
      }

      // Check if the practice path has a released video
      if(filtering.onlyWithNoReleasedVideo && practicePathBaseInformations.video.releasedVideo) {
        return false;
      }

      // Check if the practice path is free
      if(filtering.onlyFreePracticePaths && practicePathBaseInformations.isFree == false) {
        return false;
      }

      // Check if the practice path is in the correct edit state
      if(filtering.editState && filtering.editState !== practicePathBaseInformations.editState) {
        return false;
      }

      if(filtering.zoneUuid){
        if(!practicePathBaseInformations.zoneUuids.includes(filtering.zoneUuid)){
          return false;
        }
      }

      // Check if the practice path is touches or is in the correct node
      if(filtering.nodeUuid) {
        // If it is a full practice path
        if(practicePathBaseInformations.isFullPracticePath) {
          // Check the nodeTouches array
          const isTouchingPracticePath:boolean = (practicePathBaseInformations.nodeTouches ?? []).find(
            matchingPropertyPredicate("nodeUuid", filtering.nodeUuid)
          ) != undefined;

          // If it is not in it, filter out this path
          if(isTouchingPracticePath == false) {
            return false;
          }
        }else{
          // If not full practice path
          return false;
        }
      }

      // Check if the name contains the provided string
      const practicePathNameLowerCase:string = practicePathBaseInformations.name.toLocaleLowerCase();
      const nameOrUuidContainsLowerCase:string = filtering.nameOrUuidContains?.toLocaleLowerCase() ?? "";
      const isPracticePathNameContains:boolean = practicePathNameLowerCase.includes(nameOrUuidContainsLowerCase);
      const isPracticePatUuidContains:boolean = practicePathBaseInformations.uuid.includes(nameOrUuidContainsLowerCase);
      if(nameOrUuidContainsLowerCase && !isPracticePathNameContains && !isPracticePatUuidContains) {
        // If the name didn't contain it, the path number still could (full practice paths)
        if(
          practicePathBaseInformations.isFullPracticePath == false ||
          practicePathBaseInformations.examPathNumber + 1 !== +nameOrUuidContainsLowerCase
        ) {
          return false;
        }
      }

      return true;
    }
  }

  /**
   * Removes a practice path base information from the actual array.
   *
   * @param practicePathUuid the uuid of the practice path
   */
  public async removePracticePath(practicePathUuid:string):Promise<void> {
    // Delete the practice path from the server
    await this.practicePathService.deletePracticePath(practicePathUuid);

    // Remove the practice path from the locally stored practice paths
    const practicePathBaseInformations:Array<PracticePathBaseInformations> = this.practicePathBaseInformationsSubject.value;
    practicePathBaseInformations.removeItems(matchingPropertyPredicate("uuid", practicePathUuid));
    this.practicePathBaseInformationsSubject.next(practicePathBaseInformations);
  }

  /**
   * Checks if the given cityUuid is can be bound in the city names array (thus it's validation).
   *
   * @param cityUuid the uuid of the city
   *
   * @returns the uuid's validity
   */
  private isCityUuidInCityNamesValid(cityUuid:string):boolean {
    return this.practiceCityNamesAndResponsiblesSubject.value.find(
      (practiceCityNameAndResponsibles:PracticeCityNameAndResponsibles) => {
        return practiceCityNameAndResponsibles.uuid === cityUuid;
      }
    ) != undefined;
  }

  /**
   * Updates the city's uuid in the query params. It uses the navigation queue service to
   * prevent the simultaneous navigations within the component. It also uses the 'merge'
   * query params handling strategy to preserve the other query params.
   *
   * @param cityUuid the uuid of the city
   */
  private async updateCityUuidInParams(cityUuid:string):Promise<void> {
    await this.navigationQueueService.navigate(
      [ "../..", cityUuid, "paths" ],
      {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParamsHandling: "preserve"
      }
    );
  }

  /**
   * Gets the initial city's uuid from the path params . If the city uuid is invalid or cannot be found,
   * it uses the first entry from the fetched city names and updates the URL accordingly.
   *
   * @returns the cityUuid of the initial city
   */
  private async getInitialCityUuid():Promise<string> {
    let cityUuid:string = this.activatedRoute.snapshot.params.cityUuid;

    // If the cityUuid not exists or not valid (cannot be found in the fetched city names)
    if(this.isCityUuidInCityNamesValid(cityUuid) === false) {
      // Use the first one by default
      // Note: at this point the array has at least one element
      cityUuid = this.practiceCityNamesAndResponsiblesSubject.value[0].uuid;
      // Update the route param
      await this.updateCityUuidInParams(cityUuid);
    }

    // Return the city uuid
    return cityUuid;
  }

}

export enum PracticePageInitializationState {
  Loading,
  ErrorDuringFetchingCityNames,
  AdminIsNotAssignedToAnyCityError,
  AdminIsNotAssignedToCityError,
  ErrorDuringFetchingCityDetails,
  Error,
  SucessfullyInitialized
}

export class PracticePageInitializationError {
  public readonly initializationState:PracticePageInitializationState;
  public readonly error:any;

  constructor(initializationState:PracticePageInitializationState, error?:any) {
    this.initializationState = initializationState;
    this.error = error;
  }
}

const practiceCityNameAndResponsiblesFields:Array<keyof PracticeCity> = [
  "uuid", "city", "contentResponsibles"
];

export type PracticeCityNameAndResponsibles = {
  uuid:string;
  city:string;
  contentResponsibles:Array<string>;
}
